import React from "react";

import TwitterIcon from "@material-ui/icons/Twitter";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

import styles from "./landing.module.scss";

const Landing = () => {
    return (
        <div className={styles.landing}>
            <h1 className={styles.head}>Prikshit Tekta</h1>
            <div className={styles.description}>Software Engineer</div>
            <div className={styles.social}>
                <div>
                    <a href="https://twitter.com/itsiprikshit" target="blank">
                        <TwitterIcon className={styles.socialIcon} />
                    </a>
                </div>
                <div>
                    <a href="https://github.com/itsiprikshit" target="blank">
                        <GitHubIcon className={styles.socialIcon} />
                    </a>
                </div>
                <div>
                    <a href="https://linkedin.com/in/prikshit" target="blank">
                        <LinkedInIcon className={styles.socialIcon} />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Landing;
