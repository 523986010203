import React from "react";

import Layout from "../components/Layout";
import Landing from "../components/Landing";
import Seo from "../components/Seo";

export default function Home() {
    return (
        <Layout>
            <Seo
                title="Prikshit Tekta"
                keywords={[
                    `prikshit`,
                    `tekta`,
                    `portfolio`,
                    `blog`,
                    `projects`
                ]}
            />
            <Landing />
        </Layout>
    );
}
